export const featuredItemsStyles = {
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 4,
  justifyContent: "center",
  padding: "6rem 0",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "50px", // Adjust the height for shadow size
    background:
      "linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0))",
    zIndex: 1,
  },
  "&::after": {
    content: '""',
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "50px", // Adjust the height for shadow size
    background: "linear-gradient(to top, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0))",
    zIndex: 1,
  },
}

export const glowingTitleStyles = {
  display: "flex",
  justifyContent: "center",
  padding: ".5rem",
  marginBottom: "1rem",
  boxShadow: "0 0 35px 25px rgba(78, 129, 255, 0.7)", // Yellow glow around the entire box
}

export const featuredItemsTitleStyles = {
  textAlign: "center",
  fontStyle: "italic",
  fontSize: 24,
  fontWeight: 600,
}

export const itemsWrapperStyles = {
  padding: "1rem",
  gap: 2,
  justifyContent: "center",
}

export const cardStyles = { maxWidth: 300, cursor: "pointer" }
