import React from "react"
import { formReducer, defaultFormState } from "./formReducer"
import FormContext from "./form-context"

const FormProvider = (props) => {
  const [formState, dispatchFormAction] = React.useReducer(
    formReducer,
    defaultFormState
  )
  const addingOrderFormData = (_payload) => {
    dispatchFormAction({ type: "ADD_ORDER_FORM_DATA", payload: _payload })
  }
  const formContext = {
    orderFormData: formState.orderFormData,
    addOrderFormData: addingOrderFormData,
  }
  return (
    <FormContext.Provider value={formContext}>
      {props.children}
    </FormContext.Provider>
  )
}

export default FormProvider
