export const inventoryPageWrapper = {
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  gap: 4,
  alignItems: "center",
}

export const inventoryWrapperStyles = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: { xs: 2, sm: 4 },
}

export const categoryHeaderWrapperStyles = {
  display: "flex",
  alignItems: "center",
  padding: 2,
  border: "2px solid black",
  borderBottom: "none",
  backgroundColor: "white",
  gap: 1,
}
