import React, { useContext, useState } from "react"
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  MenuItem,
  Menu,
  List,
  ListItem,
  ListItemButton,
  useMediaQuery,
  useTheme,
  Button,
} from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import MenuIcon from "@mui/icons-material/Menu"
import MenuDrawer from "../MenuDrawer/MenuDrawer"
import medicalData from "../../Medical_Supply_List.json"
import { Search, SearchIconWrapper, StyledInputBase } from "../SearchComponents"
import hospitalData from "../../All_Hospitals_USA.json" // Import the hospital JSON file
import HomeIcon from "@mui/icons-material/Home"
import { Link } from "react-router-dom"
import FormContext from "../../Contexts/form-context"
import InfoIcon from "@mui/icons-material/Info"
import ContactPhoneIcon from "@mui/icons-material/ContactPhone"
import TokenIcon from "@mui/icons-material/Token"
import {
  flexGrow,
  iconButtonWrapperStyles,
  iconStyles,
  linkStyles,
  mobileMenuTextStyles,
  mobileSearchButtonStyles,
  moreIconWrapperStyles,
  navBarButtonStyles,
  navBarButtonWrapperStyles,
  navBarSuggestionsListStyles,
  searchTextStyles,
} from "./NavBar.styles"
import OrderingModal from "../OrderingModal/OrderingModal"

function NavBar() {
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [searchInput, setSearchInput] = useState("")
  const [suggestions, setSuggestions] = useState([])
  const [selectedItem, setSelectedItem] = useState(null) // State for selected item in modal
  const [hospitalName, setHospitalName] = useState("")
  const [quantity, setQuantity] = useState(null)
  const [hospitalSuggestions, setHospitalSuggestions] = useState([])
  const formCtx = useContext(FormContext)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const openMenu = () => {
    setIsDrawerOpen(true)
  }

  const closeMenu = () => {
    setIsDrawerOpen(false)
  }

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  // const menuId = "primary-search-account-menu"
  const mobileMenuId = "primary-search-account-menu-mobile"
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <Link to={"/"} style={linkStyles}>
        <MenuItem onClick={handleMobileMenuClose}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="default"
          >
            <HomeIcon />
          </IconButton>
          <Typography sx={mobileMenuTextStyles}>Home</Typography>
        </MenuItem>
      </Link>
      <Link to={"/inventory"} style={linkStyles}>
        <MenuItem onClick={handleMobileMenuClose}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="default"
          >
            <TokenIcon />
          </IconButton>
          <Typography sx={mobileMenuTextStyles}>Inventory</Typography>
        </MenuItem>
      </Link>
      <Link to={"/about"} style={linkStyles}>
        <MenuItem onClick={handleMobileMenuClose}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="default"
          >
            <InfoIcon />
          </IconButton>
          <Typography sx={mobileMenuTextStyles}>About</Typography>
        </MenuItem>
      </Link>
      <Link to={"/contact"} style={linkStyles}>
        <MenuItem onClick={handleMobileMenuClose}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="default"
          >
            <ContactPhoneIcon />
          </IconButton>
          <Typography sx={mobileMenuTextStyles}>Contact</Typography>
        </MenuItem>
      </Link>
    </Menu>
  )

  // Flatten medicalData to get all items in a single array with relevant fields
  const allItems = Object.values(medicalData).flatMap((category) =>
    category.map((item) => ({
      mfr_number: item.mfr_number ? String(item.mfr_number) : "",
      description: item.description || "",
      manufacturer: item.manufacturer || "",
      unit: item.unit || "",
      price: item.price || "",
    }))
  )

  // Handle search input change for typeahead
  const handleSearchInputChange = (e) => {
    const value = e.target.value.toLowerCase()
    setSearchInput(value)

    if (value.length > 0) {
      const matchingItems = allItems
        .filter(
          (item) =>
            item.mfr_number.toLowerCase().includes(value) ||
            item.description.toLowerCase().includes(value)
        )
        .slice(0, 5)

      setSuggestions(matchingItems)
    } else {
      setSuggestions([])
    }
  }

  // Handle clicking a suggestion
  const handleSuggestionClick = (item) => {
    setSelectedItem(item) // Set selected item for the modal
    setSearchInput("")
    handleInputBlur()
    setSuggestions([]) // Clear suggestions
  }

  // Handle modal close
  const handleClose = () => {
    setHospitalSuggestions([])
    setHospitalName("")
    setQuantity(null)
    setSelectedItem(null) // Clear selected item to close modal
  }

  // Handle hospital input change for typeahead
  const handleHospitalNameChange = (e) => {
    const value = e.target.value
    setHospitalName(value)

    // Filter hospitalData for matching names
    const matchingHospitals = hospitalData
      .filter((hospital) =>
        hospital.hospital_name.toLowerCase().includes(value.toLowerCase())
      )
      .slice(0, 5) // Limit to top 5 suggestions

    setHospitalSuggestions(matchingHospitals)
  }

  // Clear suggestions on blur
  const handleInputBlur = () => {
    setTimeout(() => {
      setSuggestions([])
      setHospitalSuggestions([])
    }, 100)
  }

  // Select a suggestion
  const handleHospitalSuggestionClick = (hospitalName) => {
    setHospitalName(hospitalName)
    setHospitalSuggestions([]) // Clear suggestions after selection
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          {isMobile && (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={mobileSearchButtonStyles}
              onClick={openMenu}
            >
              <Typography>SEARCH</Typography>
            </IconButton>
          )}
          <Typography variant="h6" noWrap component="div" sx={searchTextStyles}>
            Search Inventory
          </Typography>
          {!isMobile && (
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search MFR #, or Description"
                inputProps={{ "aria-label": "Search" }}
                value={searchInput}
                onChange={handleSearchInputChange}
                onBlur={() => setTimeout(() => setSuggestions([]), 200)}
              />
            </Search>
          )}
          {/* Suggestions List */}
          {suggestions.length > 0 && (
            <List sx={navBarSuggestionsListStyles}>
              {suggestions.map((item, index) => (
                <ListItem key={index} disablePadding>
                  <ListItemButton onClick={() => handleSuggestionClick(item)}>
                    <Typography variant="body2" color="black">
                      {item.mfr_number} - {item.description}
                    </Typography>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          )}
          <Box sx={flexGrow} />
          <Box sx={iconButtonWrapperStyles}>
            <Link to={"/"} style={linkStyles}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="primary-search-account-menu"
                aria-haspopup="true"
              >
                <HomeIcon sx={iconStyles} />
              </IconButton>
            </Link>
            <Box sx={navBarButtonWrapperStyles}>
              <Link to={"/inventory"} style={linkStyles}>
                <Button sx={navBarButtonStyles}>Inventory</Button>
              </Link>
              <Link to={"/about"} style={linkStyles}>
                <Button sx={navBarButtonStyles}>About</Button>
              </Link>
              <Link to={"/contact"} style={linkStyles}>
                <Button sx={navBarButtonStyles}>Contact</Button>
              </Link>
            </Box>
          </Box>
          <Box sx={moreIconWrapperStyles}>
            <IconButton
              // size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MenuIcon sx={{ fontSize: "50px" }} />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      <MenuDrawer closeMenu={closeMenu} isDrawerOpen={isDrawerOpen} />

      {/* Modal */}
      <OrderingModal
        selectedItem={selectedItem}
        handleClose={handleClose}
        setQuantity={setQuantity}
        quantity={quantity}
        hospitalName={hospitalName}
        handleHospitalNameChange={handleHospitalNameChange}
        handleInputBlur={handleInputBlur}
        suggestions={hospitalSuggestions}
        handleSuggestionClick={handleHospitalSuggestionClick}
        formCtx={formCtx}
      />
    </Box>
  )
}

export default NavBar
