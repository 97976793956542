import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  CircularProgress,
  TextField,
  useTheme,
  useMediaQuery,
} from "@mui/material"
import React, { useContext, useState, useEffect, useRef } from "react"
import inventory from "../../Medical_Supply_List.json"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import TokenIcon from "@mui/icons-material/Token"
import FormContext from "../../Contexts/form-context"
import { Link } from "react-router-dom"
import {
  categoryHeaderWrapperStyles,
  inventoryPageWrapper,
  inventoryWrapperStyles,
} from "./InventoryList.styles"
import {
  otherHeroWrapperStyles,
  otherHeroInnerWrapperStyles,
  h1Styles,
  h3Styles,
} from "../../Components/Hero/Hero.styles"
import supplyChainImage from "../../inventoryHero.webp"
import { Helmet } from "react-helmet"
import ReportProblemIcon from "@mui/icons-material/ReportProblem"
import ReactGa from "react-ga"

const InventoryList = () => {
  const formCtx = useContext(FormContext)
  const [visibleCategories, setVisibleCategories] = useState(3)
  const loadMoreRef = useRef(null)
  const [selectedCategories, setSelectedCategories] = useState([])
  const [filterText, setFilterText] = useState("")
  const [loading, setLoading] = useState(false)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const categoryNames = Object.keys(inventory).sort((a, b) => {
    if (a === "MISCELLANEOUS") return 1 // Push MISCELLANEOUS to the end
    if (b === "MISCELLANEOUS") return -1
    return a.localeCompare(b) // Maintain alphabetical order for other categories
  })

  const handleCategoryFilterChange = (event) => {
    const {
      target: { value },
    } = event
    setSelectedCategories(typeof value === "string" ? value.split(",") : value)
  }

  const handleFilterTextChange = (event) => {
    setFilterText(event.target.value.toLowerCase())
  }

  const handleClearSelection = () => {
    setSelectedCategories([]) // Clear all selected categories
    setFilterText("") // Clear text filter
  }

  useEffect(() => {
    const currentRef = loadMoreRef.current
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !loading) {
          if (visibleCategories < categoryNames.length) {
            setLoading(true) // Show spinner
            setTimeout(() => {
              setVisibleCategories((prev) => prev + 2) // Load more categories after delay
              setLoading(false) // Hide spinner after loading
            }, 200) // Simulated loading delay
          }
        }
      },
      { threshold: 1.0 }
    )
    if (currentRef) {
      observer.observe(currentRef)
    }
    return () => {
      if (currentRef) {
        observer.unobserve(currentRef)
      }
    }
  }, [visibleCategories, loading, categoryNames.length])

  const formatTextWithStyles = (text) => {
    const [beforeColon, afterColon] = text.split(": ")
    return (
      <Box sx={{ display: "flex" }}>
        <Typography sx={{ fontWeight: 600 }}>{beforeColon}</Typography>
        <Typography sx={{ fontWeight: 400 }}>: {afterColon}</Typography>
      </Box>
    )
  }

  const clickCreateAnOrderHandler = () => {
    ReactGa.event({
      category: "Button",
      action: "Create An Order from Inventory Page",
    })
  }

  return (
    <Box sx={inventoryPageWrapper} data-test-id="inventoryWrapper">
      <Helmet>
        <title>Express-Med1 | Inventory</title>
      </Helmet>
      <Box
        data-test-id="inventory_hero"
        sx={otherHeroWrapperStyles(isMobile, supplyChainImage)}
      >
        <Box sx={otherHeroInnerWrapperStyles}>
          <Typography variant="h1" sx={h1Styles}>
            Inventory
          </Typography>
          <Typography variant="h3" sx={h3Styles}>
            Browse our stocking list of 5000+ products
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // flexWrap: "wrap",
          // gap: 1,
          padding: 2,
          backgroundColor: "white",
          width: "fit-content",
          borderRadius: { xs: "none", sm: "20px" },
        }}
      >
        <ReportProblemIcon />

        <Typography textAlign={"center"} sx={{ marginLeft: "8px" }}>
          <a
            href="mailto:orders@expressmed1.org"
            target="blank"
            rel="noreferrer"
          >
            Email us directly
          </a>{" "}
          about our 500,000+ items from 5000+ manufacturers if you're not seeing
          the product you're looking for
        </Typography>
      </Box>

      {/* Category and Text Filter */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
          gap: 1,
          padding: 1,
          backgroundColor: "white",
          width: "fit-content",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <FormControl sx={{ m: 1, width: { xs: 200, sm: 300 } }}>
            <InputLabel color="success">Filter by Category</InputLabel>
            <Select
              multiple
              value={selectedCategories}
              onChange={handleCategoryFilterChange}
              renderValue={(selected) => selected.join(", ")}
              input={
                <OutlinedInput color="success" label="Filter by Category" />
              }
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300,
                    overflow: "scroll",
                  },
                },
              }}
            >
              {categoryNames
                .filter((name) => name !== "MISCELLANEOUS") // Exclude "MISCELLANEOUS" from the dropdown
                .map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={selectedCategories.includes(name)} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <TextField
            label="Filter by Description or MFR#"
            variant="outlined"
            color="success"
            size="small"
            value={filterText}
            onChange={handleFilterTextChange}
            sx={{ m: 1, width: { xs: 200, sm: 300 } }}
          />
        </Box>
        <Box>
          <Button
            variant="contained"
            onClick={handleClearSelection}
            size="small"
          >
            Clear
          </Button>
        </Box>
      </Box>
      {/* Conditional Rendering */}
      {selectedCategories.length === 0 && filterText === "" ? (
        <Box
          sx={{
            textAlign: "center",
            margin: 4,
            backgroundColor: "white",
            padding: 2,
            borderRadius: "20px",
          }}
        >
          <Typography variant="h6" color="textSecondary">
            Please apply a filter to view the inventory.
          </Typography>
        </Box>
      ) : (
        <Box sx={inventoryWrapperStyles}>
          {filterText === "" && (
            <Typography
              textAlign={"center"}
              sx={{
                backgroundColor: "white",
                width: "fit-content",
                padding: "1rem",
                borderRadius: "1rem",
                marginBottom: "1rem",
              }}
            >
              Not seeing what you're looking for? Try filtering by MFR #
            </Typography>
          )}
          {categoryNames
            .filter((categoryName) => {
              // Check if the category has any items matching the text filter
              const filteredItems = inventory[categoryName].filter(
                (item) =>
                  item.description.toLowerCase().includes(filterText) ||
                  item.mfr_number.toLowerCase().includes(filterText)
              )
              return (
                (selectedCategories.length === 0 ||
                  selectedCategories.includes(categoryName)) &&
                (filterText === "" || filteredItems.length > 0)
              ) // Include only categories with matching items when text filter is applied
            })
            .slice(0, visibleCategories)
            .map((categoryName) => (
              <Box key={categoryName} sx={{ marginBottom: 3 }}>
                <Box sx={categoryHeaderWrapperStyles}>
                  <TokenIcon />
                  <Typography variant="h6">{categoryName}</Typography>
                </Box>

                {inventory[categoryName]
                  .filter(
                    (item) =>
                      item.description.toLowerCase().includes(filterText) ||
                      item.mfr_number.toLowerCase().includes(filterText)
                  )
                  .slice(
                    0,
                    filterText !== "" ? 5 : inventory[categoryName].length
                  ) // Limit to 5 if text filter is applied
                  .sort((a, b) => a.description.localeCompare(b.description))
                  .map((item, index) => (
                    <Accordion key={index}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`${categoryName}-content-${index}`}
                        id={`${categoryName}-header-${index}`}
                      >
                        <Typography>{item.description}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box>
                          {formatTextWithStyles(
                            `Manufacturer: ${item.manufacturer}`
                          )}
                        </Box>
                        <Box>
                          {formatTextWithStyles(`MFR#: ${item.mfr_number}`)}
                        </Box>
                        <Box>
                          {formatTextWithStyles(
                            `Unit of Measure: ${item.unit}`
                          )}
                        </Box>
                        {/* <Box>
                          {formatTextWithStyles(
                            `Quantity per Order: ${item.quantity}`
                          )}
                        </Box> */}
                        <Box>
                          {formatTextWithStyles(
                            `Price per Order: $${item.price}`
                          )}
                        </Box>
                      </AccordionDetails>
                      <AccordionActions>
                        <Link to={"/order"}>
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => {
                              clickCreateAnOrderHandler()
                              formCtx.addOrderFormData({
                                selectedItem: item.description,
                                // quantity: 0,
                                hospitalName: null,
                              })
                            }}
                          >
                            Create an Order
                          </Button>
                        </Link>
                      </AccordionActions>
                    </Accordion>
                  ))}
              </Box>
            ))}
          {/* Load More Trigger and Spinner */}
          <Box
            ref={loadMoreRef}
            sx={{
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {loading && visibleCategories < categoryNames.length && (
              <CircularProgress />
            )}
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default InventoryList
