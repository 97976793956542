export const drawerHeaderWrapper = {
  display: "flex",
  justifyContent: "end",
  backgroundColor: "white",
}

export const drawerImageOuterWrapper = {
  borderBottom: "2px solid black",
  backgroundColor: "white",
}

export const drawerImageInnerWrapper = {
  width: "100%",
  height: "auto",
  display: "flex",
  justifyContent: "center",
}

export const drawerImageStyles = { width: "100%" }

export const drawerSearchTitleStyles = { padding: 2, textAlign: "center" }

export const searchBarStyles = { marginX: "20px", width: "90%" }

export const searchInputBaseStyles = {
  border: "1px solid black",
  borderRadius: "10px",
  display: "flex",
  justifyContent: "center",
}

export const browseInventoryWrapperStyles = {
  display: "flex",
  justifyContent: "center",
  padding: "2rem 2rem 0 2rem",
}
