import React from "react"
import {
  Box,
  Typography,
  Grid2,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material"
import {
  h1Styles,
  h3Styles,
  otherHeroInnerWrapperStyles,
  otherHeroWrapperStyles,
} from "../Components/Hero/Hero.styles"
import aboutImage from "../expressmed1About.webp"
import { Helmet } from "react-helmet"

const About = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  return (
    <Box>
      <Helmet>
        <title>Express-Med1 | About</title>
      </Helmet>
      <Box
        data-test-id="inventory_hero"
        sx={otherHeroWrapperStyles(isMobile, aboutImage)}
      >
        <Box sx={otherHeroInnerWrapperStyles}>
          <Typography variant="h1" sx={h1Styles}>
            About Us
          </Typography>
          <Typography variant="h3" sx={h3Styles}>
            Delivering over 20 years of industry experience
          </Typography>
        </Box>
      </Box>
      <Box sx={{ padding: "2rem" }}>
        <Box
          sx={{
            backgroundColor: "primary.main",
            color: "white",
            borderRadius: 2,
            p: 4,
            mb: 6,
            textAlign: "center",
          }}
        >
          <Typography variant="h4" gutterBottom>
            About Us
          </Typography>
          <Typography variant="body1" sx={{ maxWidth: 600, mx: "auto" }}>
            Delivering innovation and reliability in medical device automation
            for over two decades. Your partner in healthcare success.
          </Typography>
        </Box>

        {/* Company Overview Section */}
        <Grid2 container spacing={4}>
          <Grid2 item xs={12} md={6}>
            <Box>
              <Typography variant="h5" gutterBottom>
                Who We Are
              </Typography>
              <Typography variant="body1">
                At Express-Med1, we are dedicated to providing cutting-edge unit
                dose systems and equipment with advanced automation software.
                With over 20 years in the industry, we are proud to be a trusted
                partner to healthcare providers nationwide.
              </Typography>
            </Box>
          </Grid2>
          <Grid2 item xs={12} md={6}>
            <Box>
              <Typography variant="h5" gutterBottom>
                What We Do
              </Typography>
              <Typography variant="body1">
                From state-of-the-art unit dose packaging equipment to seamless
                supply chain solutions, we offer a comprehensive range of
                products and services to support your healthcare needs. Our
                systems are designed to streamline operations and improve
                patient care.
              </Typography>
            </Box>
          </Grid2>
        </Grid2>

        {/* Experience and Expertise Section */}
        <Box sx={{ my: 6 }}>
          <Typography variant="h5" gutterBottom align="center">
            Why Choose Us
          </Typography>
          <Typography
            variant="body1"
            align="center"
            sx={{ maxWidth: 800, mx: "auto" }}
          >
            With unparalleled experience and strong connections to top
            manufacturers, we ensure your facility is equipped with the latest
            innovations in medical technology. Our commitment to excellence has
            made us the preferred vendor for healthcare systems nationwide.
          </Typography>
        </Box>

        {/* Call to Action Section */}
        <Box
          sx={{
            mt: 6,
            py: 4,
            textAlign: "center",
            backgroundColor: "grey.100",
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Ready to Get Started?
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Contact us today to discuss your healthcare needs with one of our
            specialists.
          </Typography>
          <a href="mailto:orders@expressmed1.org">
            <Button variant="contained" color="primary" size="large">
              Email Us
            </Button>
          </a>
        </Box>
      </Box>
    </Box>
  )
}

export default About
