import React, { useContext, useState } from "react"
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Button,
  TextField,
  AccordionActions,
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome"
import FormContext from "../../Contexts/form-context"
import medicalData from "../../ShortStockList.json" // Import short stock list data
import {
  featuredItemsStyles,
  featuredItemsTitleStyles,
  glowingTitleStyles,
  itemsWrapperStyles,
} from "./FeaturedItems.styles"
import { Link } from "react-router-dom"
import ReactGa from "react-ga"

const FeaturedItems = () => {
  const [selectedCategories, setSelectedCategories] = useState([])
  const [filterText, setFilterText] = useState("")
  const formCtx = useContext(FormContext)

  // Sort categories alphabetically
  const categoryNames = Object.keys(medicalData).sort((a, b) =>
    a.localeCompare(b)
  )

  const handleCategoryFilterChange = (event) => {
    const {
      target: { value },
    } = event
    setSelectedCategories(typeof value === "string" ? value.split(",") : value)
  }

  const handleFilterTextChange = (event) => {
    setFilterText(event.target.value.toLowerCase())
  }

  const handleClearSelection = () => {
    setSelectedCategories([])
    setFilterText("")
  }

  const clickFeaturedItemsCreateAnOrderHandler = () => {
    ReactGa.event({
      category: "Button",
      action: "Create An Order from Featured Items",
    })
  }

  return (
    <Box data-test-id="featured-items" sx={featuredItemsStyles}>
      <Box width={"60%"} sx={glowingTitleStyles}>
        <AutoAwesomeIcon />
        <Typography sx={featuredItemsTitleStyles}>Featured Items</Typography>
        <AutoAwesomeIcon />
      </Box>

      {/* Filters */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginBottom: 2,
          padding: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 2,
            flexWrap: "wrap",
            justifyContent: "center",
            backgroundColor: "white",
            padding: "1rem",
            borderRadius: ".25rem",
          }}
        >
          <FormControl
            sx={{
              width: 300,
            }}
          >
            <InputLabel color="success">Filter by Category</InputLabel>
            <Select
              multiple
              value={selectedCategories}
              onChange={handleCategoryFilterChange}
              renderValue={(selected) => selected.join(", ")}
              input={
                <OutlinedInput color="success" label="Filter by Category" />
              }
            >
              {categoryNames.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={selectedCategories.includes(name)} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Filter by Description or MFR#"
            variant="outlined"
            color="success"
            value={filterText}
            onChange={handleFilterTextChange}
            sx={{
              width: 300,
            }}
          />
          <Button variant="contained" onClick={handleClearSelection}>
            Clear
          </Button>
        </Box>
      </Box>

      {/* Grouped Categories with Accordions */}
      <Box sx={itemsWrapperStyles}>
        {categoryNames
          .filter((categoryName) => {
            const filteredItems = medicalData[categoryName].filter((item) => {
              const description = (item.description || "").toLowerCase()
              const mfrNumber = (item.mfr_number || "").toString().toLowerCase()
              return (
                description.includes(filterText) ||
                mfrNumber.includes(filterText)
              )
            })
            return (
              (selectedCategories.length === 0 ||
                selectedCategories.includes(categoryName)) &&
              (filterText === "" || filteredItems.length > 0)
            )
          })
          .map((categoryName) => (
            <Box key={categoryName} sx={{ marginBottom: 3 }}>
              <Typography variant="h6" sx={{ marginBottom: 1 }}>
                {categoryName}
              </Typography>
              {medicalData[categoryName]
                .filter(
                  (item) =>
                    item.description.toLowerCase().includes(filterText) ||
                    item.mfr_number
                      .toString()
                      .toLowerCase()
                      .includes(filterText)
                )
                .map((item, index) => (
                  <Accordion key={index}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel-content-${index}`}
                      id={`panel-header-${index}`}
                    >
                      <Typography>
                        <strong>{item.mfr_number}</strong> - {item.description}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box>
                        <Typography>
                          <strong>Manufacturer:</strong> {item.manufacturer}
                        </Typography>
                        <Typography>
                          <strong>Unit of Measure:</strong> {item.unit}
                        </Typography>
                        <Typography>
                          <strong>Price:</strong> ${item.price}
                        </Typography>
                      </Box>
                      <AccordionActions>
                        <Link to={"/order"}>
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => {
                              clickFeaturedItemsCreateAnOrderHandler()
                              formCtx.addOrderFormData({
                                selectedItem: item.description,
                                hospitalName: null,
                              })
                            }}
                          >
                            Create an Order
                          </Button>
                        </Link>
                      </AccordionActions>
                    </AccordionDetails>
                  </Accordion>
                ))}
            </Box>
          ))}
      </Box>
    </Box>
  )
}

export default FeaturedItems
