export const heroWrapperStyles = (isMobile) => ({
  position: "relative",
  height: isMobile ? "55vh" : "calc(100vh - 400px)",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  overflow: "hidden",
})

export const heroImageStyles = (currentHeroImage, isFading) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundImage: `url(${currentHeroImage})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  transition: "opacity 200ms ease-in-out",
  opacity: isFading ? 0 : 1,
})

export const heroInnerWrapperStyles = {
  position: "relative",
  zIndex: 1,
  background: "rgba(0, 0, 0, 0.85)",
  padding: "1rem",
  borderRadius: "0 20px 20px 0",
  width: { sm: "60%", xs: "60%" },
}

export const h1Styles = {
  textShadow: "2px 2px 4px rgba(0, 0, 0, 0.9)",
  color: "#f0f0f0",
  fontSize: { xs: "4rem", md: "6rem" },
  fontWeight: 900,
}

export const h3Styles = {
  textShadow: "2px 2px 4px rgba(0, 0, 0, 0.9)",
  fontSize: { xs: "1.5rem", md: "3rem" },
  color: "#f0f0f0",
}

export const otherHeroWrapperStyles = (isMobile, heroImage) => ({
  backgroundImage: `url(${heroImage})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  height: isMobile ? "60vh" : "calc(100vh - 400px)",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
})

export const otherHeroInnerWrapperStyles = {
  background: "rgba(0, 0, 0, 0.85)",
  padding: "1rem",
  borderRadius: "0 20px 20px 0",
  width: { sm: "60%", xs: "75%" },
}
