export const defaultFormState = {
  hospitalName: "",
  contactPerson: "",
  address: "",
  city: "",
  state: "",
  zip: "",
  contactEmail: "",
  contactPhone: "",
  itemDescription: "",
  manufacturer: "",
  mfrNumber: "",
  unit: "",
  quantity: 0,
  price: "",
  selectedItem: "",
  isNewHospitalData: false,
}

export const formReducer = (state, action) => {
  switch (action.type) {
    case "ADD_ORDER_FORM_DATA":
      const formData = action.payload
      return {
        ...state,
        orderFormData: {
          hospitalName: formData.hospitalName,
          contactPerson: formData.contactPerson,
          address: formData.address,
          city: formData.city,
          state: formData.state,
          zip: formData.zip,
          contactEmail: formData.contactEmail,
          contactPhone: formData.contactPhone,
          itemDescription: formData.itemDescription,
          manufacturer: formData.manufacturer,
          mfrNumber: formData.mfrNumber,
          unit: formData.unit,
          quantity: formData.quantity,
          price: formData.price,
          selectedItem: formData.selectedItem,
          isNewHospitalData: formData.isNewHospitalData,
        },
      }

    default:
      return defaultFormState
  }
}
