import { Box, Typography, useMediaQuery, useTheme } from "@mui/material"
import React from "react"
import logo from "../../expressmed1-logo-transparent.png"
import {
  mobileFooterImageStyles,
  footerInnerWrapperStyles,
  footerWrapperStyles,
  phoneNumberStyles,
  footerImage,
} from "./Footer.styles"

const Footer = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  return (
    <Box sx={footerWrapperStyles}>
      <Box textAlign={"center"}>
        <Typography fontSize={24} fontWeight={600} fontStyle={"italic"}>
          Delivering Excellence in Healthcare Solutions and Supply Chain
          Management
        </Typography>
      </Box>
      <hr />
      <Box sx={footerInnerWrapperStyles(isMobile)}>
        <Box textAlign={{ xs: "center", sm: "left" }}>
          <Typography fontSize={18} fontWeight={600}>
            Express-Med 1
          </Typography>
          <Typography>1 Overlook Point, Ste 110</Typography>
          <Typography>Lincolnshire, IL 60069</Typography>
          <a style={phoneNumberStyles} href="tel:224-213-4339">
            <Typography>224-213-4339</Typography>
          </a>
          <a
            href="mailto:orders@expressmed1.org"
            target="blank"
            rel="noreferrer"
          >
            Orders@expressmed1.org
          </a>
          {isMobile && (
            <img src={logo} alt="Logo" style={mobileFooterImageStyles} />
          )}
        </Box>

        {!isMobile && <img src={logo} alt="Logo" style={footerImage} />}
      </Box>
    </Box>
  )
}

export default Footer
