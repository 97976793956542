export const linkStyles = { textDecoration: "none" }

export const mobileMenuTextStyles = { color: "black", textDecoration: "none" }

export const mobileSearchButtonStyles = {
  ml: 1,
  border: "1px solid white",
  borderRadius: "20px",
}

export const searchTextStyles = { display: { xs: "none", sm: "block" } }

export const navBarSuggestionsListStyles = {
  position: "absolute",
  top: "50px",
  left: "200px",
  bgcolor: "background.paper",
  width: "350px",
  zIndex: 2,
  border: "1px solid black",
}

export const navBarButtonWrapperStyles = {
  display: "flex",
  flexDirection: "row",
  gap: 2,
}

export const navBarButtonStyles = { color: "white", border: "1px solid white" }

export const flexGrow = { flexGrow: 1 }

export const iconButtonWrapperStyles = {
  display: { xs: "none", md: "flex" },
  alignItems: "center",
}

export const iconStyles = { color: "white", fontSize: "40px" }

export const moreIconWrapperStyles = { display: { xs: "flex", md: "none" } }
