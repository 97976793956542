import "./App.css"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import Home from "./Pages/Home"
import NavBar from "./Components/NavBar/NavBar"
import Footer from "./Components/Footer/Footer"
import OrderForm from "./Pages/OrderForm/OrderForm"
import Contact from "./Pages/Contact"
import About from "./Pages/About"
import InventoryList from "./Pages/InventoryList/InventoryList"

function App() {
  return (
    <div style={{ backgroundColor: "#d6d6d6", height: "120%" }}>
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/order" element={<OrderForm />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/inventory" element={<InventoryList />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  )
}

export default App
