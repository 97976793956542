import React from 'react';

const GoogleMap = () => {
  return (
    <div style={{ width: '100%', height: '400px', position: 'relative' }}>
      <iframe
        title="Google Map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2965.1660458125776!2d-87.93138272464696!3d42.18826037316865!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880f8c3a95bc9437%3A0x3c9c7d0241bdc4bb!2s1%20Overlook%20Point%2C%20Lincolnshire%2C%20IL%2060069%2C%20USA!5e0!3m2!1sen!2sus!4v1696962960266!5m2!1sen!2sus"
        style={{ border: 0, width: '100%', height: '100%' }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default GoogleMap;
