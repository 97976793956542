import React from "react"
import Hero from "../Components/Hero/Hero"
import FeaturedItems from "../Components/FeaturedItems/FeaturedItems"
import { Helmet } from "react-helmet"

const Home = () => {
  return (
    <div>
      <Helmet>
        <title>Express-Med1 | Quality Medical Supplies</title>
      </Helmet>
      <Hero />
      <FeaturedItems />
    </div>
  )
}

export default Home
