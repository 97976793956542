export function formatPhoneNumber(phoneNumberString) {
  const cleanedPhoneNumber = ("" + phoneNumberString).replace(/\D/g, "")

  const matchPhoneNumberFormat = cleanedPhoneNumber.match(
    /^(\d{3})(\d{3})(\d{4})$/
  )
  if (matchPhoneNumberFormat) {
    return (
      "(" +
      matchPhoneNumberFormat[1] +
      ") " +
      matchPhoneNumberFormat[2] +
      "-" +
      matchPhoneNumberFormat[3]
    )
  }
  return null
}
