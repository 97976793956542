export const footerWrapperStyles = {
  borderTop: "1px solid black",
  padding: "3rem 1rem",
}

export const footerInnerWrapperStyles = (isMobile) => ({
  ...(!isMobile && {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  }),
})

export const phoneNumberStyles = { backgroundColor: "#d6d6d6" }

export const mobileFooterImageStyles = { width: "100%" }

export const footerImage = { width: "200px", height: "auto" }
