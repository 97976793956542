export const orderFormWrapperStyles = {
  display: "flex",
  flexDirection: "column",
  gap: 2,
  width: { xs: "80%", sm: "50%" },
  mx: "auto",
  marginY: 4,
}

export const hospitalSuggestionListStyles = {
  position: "absolute",
  width: { xs: "80%", sm: "50%" },
  zIndex: 2,
  backgroundColor: "white",
  top: 212,
  border: "1px solid black",
}

export const disabledFieldStyles = {
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "black", // This property often works better for disabled color in some browsers
  },
}

export const disabledCurrencyStyles = {
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "Blue", // This property often works better for disabled color in some browsers
  },
}

export const orderModalWrapperStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
}

export const orderModalTextFieldWrapperStyles = {
  display: "flex",
  flexDirection: "column",
  gap: 2,
  justifyContent: "center",
}

export const orderModalButtonWrapperStyles = {
  display: "flex",
  justifyContent: "space-between",
  paddingTop: "1rem",
}

export const confirmModalButtonWrapperStyles = {
  display: "flex",
  justifyContent: "center",
  paddingTop: "1rem",
}
