import React, { useState, useEffect, useContext } from "react"
import {
  Box,
  TextField,
  Typography,
  Button,
  Modal,
  List,
  ListItem,
  ListItemButton,
  CircularProgress,
} from "@mui/material"
import FormContext from "../../Contexts/form-context"
import medicalData from "../../Medical_Supply_List.json"
import shortStockList from "../../ShortStockList.json"
import hospitalData from "../../All_Hospitals_USA.json"
// import { Link } from "react-router-dom"
import {
  confirmModalButtonWrapperStyles,
  disabledCurrencyStyles,
  disabledFieldStyles,
  hospitalSuggestionListStyles,
  orderFormWrapperStyles,
  orderModalButtonWrapperStyles,
  orderModalTextFieldWrapperStyles,
  orderModalWrapperStyles,
} from "./OrderForm.styles"
import { Link } from "react-router-dom"
import axios from "axios"
import ReportIcon from "@mui/icons-material/Report"
import { formatPhoneNumber } from "../../utils/formatPhoneNumber"
import { Helmet } from "react-helmet"
import ReactGa from "react-ga"

const OrderForm = () => {
  const { orderFormData = {}, addOrderFormData } = useContext(FormContext)
  const [open, setOpen] = useState(false) // For handling the modal
  const [confirmEmail, setConfirmEmail] = useState("")
  const [confirmName, setConfirmName] = useState("")
  const [confirmPhone, setConfirmPhone] = useState("")
  const [openThankYouModal, setOpenThankYouModal] = useState(false) // New state for the thank-you modal
  const [hospitalSuggestions, setHospitalSuggestions] = useState([])
  const [emailError, setEmailError] = useState(false)
  const [sending, setSending] = useState(false)
  const [formData, setFormData] = useState({
    hospitalName: "",
    contactPerson: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    contactEmail: "",
    contactPhone: "",
    itemDescription: "",
    manufacturer: "",
    mfrNumber: "",
    unit: "",
    quantity: orderFormData.quantity || "",
    price: "",
    selectedItem: orderFormData.selectedItem || "",
    isNewHospitalData: false,
  })

  const requiredFields = [
    "hospitalName",
    "contactPerson",
    "address",
    "city",
    "state",
    "zip",
    "contactEmail",
    "contactPhone",
    "itemDescription",
    "quantity",
    "price",
  ]

  const areAllFieldsFilled = () =>
    requiredFields.every((field) => formData[field]?.toString().trim() !== "")

  useEffect(() => {
    window.scrollTo(0, 0)
    if (orderFormData.hospitalName || orderFormData.selectedItem) {
      // Populate data if available in orderFormData
      const hospitalInfo = hospitalData.find(
        (hospital) => hospital.hospital_name === orderFormData.hospitalName
      )
      if (hospitalInfo) populateHospitalInfo(hospitalInfo)

      const itemInfo = findItemInfo(orderFormData.selectedItem)
      if (itemInfo) populateItemInfo(itemInfo)
    }
  }, [orderFormData.hospitalName, orderFormData.selectedItem])

  const populateHospitalInfo = (hospitalInfo) => {
    setFormData((prevData) => ({
      ...prevData,
      hospitalName: hospitalInfo.hospital_name,
      address: hospitalInfo.address,
      city: hospitalInfo.city,
      state: hospitalInfo.state,
      zip: hospitalInfo.zip,
      isNewHospitalData: false,
    }))
  }

  const findItemInfo = (selectedItem) => {
    // Check in medicalData first
    for (const category in medicalData) {
      const item = medicalData[category].find(
        (item) => item.description === selectedItem
      )
      if (item) return item
    }

    // If not found, check in shortStockList
    for (const category in shortStockList) {
      const item = shortStockList[category].find(
        (item) => item.description === selectedItem
      )
      if (item) return item
    }

    // If not found in either list
    return null
  }

  const populateItemInfo = (itemInfo) => {
    setFormData((prevData) => ({
      ...prevData,
      itemDescription: itemInfo.description,
      manufacturer: itemInfo.manufacturer,
      mfrNumber: itemInfo.mfr_number,
      unit: itemInfo.unit,
      price: itemInfo.price,
    }))
  }

  const handleHospitalNameChange = (e) => {
    const value = e.target.value
    setFormData((prevData) => {
      const hospitalExists = hospitalData.some(
        (hospital) =>
          hospital.hospital_name.toLowerCase() === value.toLowerCase()
      )
      console.log("hospitalExists", hospitalExists)
      return {
        ...prevData,
        hospitalName: value,
        isNewHospitalData: !hospitalExists, // Update the field
      }
    })

    // Update suggestions
    setHospitalSuggestions(
      hospitalData
        .filter((hospital) =>
          hospital.hospital_name.toLowerCase().includes(value.toLowerCase())
        )
        .slice(0, 3)
    )
  }
  console.log(formData)

  const handleBlur = () => {
    setTimeout(() => setHospitalSuggestions([]), 300) // Clear suggestions when the input field loses focus
  }

  const handleHospitalSuggestionClick = (hospital) => {
    populateHospitalInfo(hospital)
    setHospitalSuggestions([]) // Clear suggestions
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData((prevData) => ({
      ...prevData,
      [name]:
        name === "contactPhone" ? formatPhoneNumber(value) || value : value, // Apply formatting to contactPhone
    }))
  }

  const handleSubmit = () => {
    addOrderFormData({ ...formData }) // Save form data to context
    setOpen(true) // Open the modal
  }
  const isFieldEmpty = (value) =>
    value === "" || value === null || value === undefined

  const sendEmail = async () => {
    setSending(true)
    try {
      const response = await axios.post(
        "https://express-med1.onrender.com/send-email",
        {
          hospitalName: orderFormData.hospitalName,
          contactPerson: orderFormData.contactPerson,
          address: orderFormData.address,
          city: orderFormData.city,
          state: orderFormData.state,
          zip: orderFormData.zip,
          contactEmail: orderFormData.contactEmail,
          contactPhone: orderFormData.contactPhone,
          itemDescription: orderFormData.itemDescription,
          manufacturer: orderFormData.manufacturer,
          mfrNumber: orderFormData.mfrNumber,
          unit: orderFormData.unit,
          quantity: orderFormData.quantity,
          price: orderFormData.price,
          subtotal: (
            orderFormData.price * orderFormData.quantity
          ).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          }),
          isNewHospitalData: orderFormData.isNewHospitalData,
        }
      )
      console.log("Email sent:", response.data)
      setEmailError(false) // Clear any previous error state
      setOpen(false) // Close confirmation modal
      setSending(false)
      setOpenThankYouModal(true) // Show success modal
    } catch (error) {
      console.error("Error sending email:", error)
      setEmailError(true) // Set error state
      setOpenThankYouModal(true) // Still open modal to show error message
    }
  }

  const clickSubmitHandler = () => {
    ReactGa.event({
      category: "Button",
      action: "Submit Form",
    })
  }
  const clickConfirmOrderHandler = () => {
    ReactGa.event({
      category: "Button",
      action: "Confirm Order",
    })
  }

  return (
    <Box component="form" sx={orderFormWrapperStyles}>
      <Helmet>
        <title>Express-Med1 | Order</title>
      </Helmet>
      <Typography variant="h4">Order Form</Typography>
      <TextField
        label="Hospital Name"
        name="hospitalName"
        value={formData.hospitalName}
        onChange={handleHospitalNameChange}
        autoComplete="off"
        error={isFieldEmpty(formData.hospitalName)}
        onBlur={handleBlur}
      />
      {/* Hospital Suggestions List */}
      {hospitalSuggestions.length > 0 && (
        <List sx={hospitalSuggestionListStyles}>
          {hospitalSuggestions.map((hospital, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton
                onClick={() => handleHospitalSuggestionClick(hospital)}
              >
                <Typography variant="body2">
                  {hospital.hospital_name}
                </Typography>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}
      {formData.isNewHospitalData && formData.hospitalName.length > 3 && (
        <Typography color="error">
          This hospital is not in our database. Please ensure the details are
          correct.
        </Typography>
      )}
      <TextField
        label="Contact Person"
        name="contactPerson"
        required
        value={formData.contactPerson}
        onChange={handleInputChange}
        error={isFieldEmpty(formData.contactPerson)}
      />
      <TextField
        label="Contact Email"
        name="contactEmail"
        type="email"
        required
        value={formData.contactEmail}
        onChange={handleInputChange}
        error={isFieldEmpty(formData.contactEmail)}
      />
      <TextField
        label="Contact Phone"
        name="contactPhone"
        required
        value={formData.contactPhone}
        onChange={handleInputChange}
        error={isFieldEmpty(formData.contactPhone)}
      />
      <TextField
        label="Address"
        name="address"
        required
        value={formData.address}
        onChange={handleInputChange}
        error={isFieldEmpty(formData.address)}
      />

      <TextField
        label="City"
        name="city"
        required
        value={formData.city}
        onChange={handleInputChange}
        error={isFieldEmpty(formData.city)}
      />

      <TextField
        label="State"
        name="state"
        required
        value={formData.state}
        onChange={handleInputChange}
        error={isFieldEmpty(formData.state)}
      />

      <TextField
        label="ZIP"
        name="zip"
        required
        value={formData.zip}
        onChange={handleInputChange}
        error={isFieldEmpty(formData.zip)}
      />

      <TextField
        label="Item Description"
        name="itemDescription"
        required
        disabled={orderFormData.selectedItem}
        multiline
        value={formData.itemDescription}
        onChange={handleInputChange}
        sx={disabledFieldStyles}
        error={
          !orderFormData.selectedItem && isFieldEmpty(formData.itemDescription)
        }
      />

      <TextField
        label="Manufacturer"
        name="manufacturer"
        required
        disabled={orderFormData.selectedItem}
        value={formData.manufacturer}
        onChange={handleInputChange}
        sx={disabledFieldStyles}
        error={
          !orderFormData.selectedItem && isFieldEmpty(formData.manufacturer)
        }
      />

      <TextField
        label="MFR Number"
        name="mfrNumber"
        required
        disabled={orderFormData.selectedItem}
        value={formData.mfrNumber}
        onChange={handleInputChange}
        sx={disabledFieldStyles}
        error={!orderFormData.selectedItem && isFieldEmpty(formData.mfrNumber)}
      />

      <TextField
        label="Unit"
        name="unit"
        required
        disabled={orderFormData.selectedItem}
        value={formData.unit}
        onChange={handleInputChange}
        sx={disabledFieldStyles}
        error={!orderFormData.selectedItem && isFieldEmpty(formData.unit)}
      />

      <TextField
        label="Quantity of Orders"
        name="quantity"
        type="number"
        required
        value={formData.quantity}
        onChange={handleInputChange}
        sx={disabledFieldStyles}
        error={isFieldEmpty(formData.quantity)}
      />

      <TextField
        label="Price Per Order"
        name="price"
        type="number"
        required
        value={formData.price}
        disabled={orderFormData.selectedItem}
        onChange={handleInputChange}
        sx={disabledFieldStyles}
        error={!orderFormData.selectedItem && isFieldEmpty(formData.price)}
      />

      <TextField
        label="Subtotal"
        name="subtotal"
        type="string"
        disabled
        value={(formData.price * formData.quantity).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })}
        sx={disabledCurrencyStyles}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          handleSubmit()
          addOrderFormData(formData)
          clickSubmitHandler()
        }}
        disabled={!areAllFieldsFilled()} // Disable if fields are empty
      >
        Submit Order
      </Button>

      {/* Modal for confirming submission */}
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={orderModalWrapperStyles}>
          <Typography variant="h6" textAlign="center">
            Confirm Contact Info to Place Order
          </Typography>
          <Box sx={orderModalTextFieldWrapperStyles}>
            <TextField
              label="Contact Person"
              name="name"
              placeholder="Must match Contact Name on form"
              onChange={(e) => {
                setConfirmName(e.target.value)
                console.log({ orderFormData })
              }}
              value={confirmName}
            />
            <TextField
              label="Contact Email"
              name="contactEmail"
              type="email"
              placeholder="Must match Contact Email on form"
              onChange={(e) => setConfirmEmail(e.target.value)}
              value={confirmEmail}
            />
            <TextField
              label="Contact Phone"
              name="contactPhone"
              placeholder="Must match Contact Phone on form"
              onChange={(e) =>
                setConfirmPhone(formatPhoneNumber(e.target.value))
              }
              value={confirmPhone}
            />
          </Box>
          <Box sx={orderModalButtonWrapperStyles}>
            <Button
              variant="contained"
              color="error"
              onClick={() => setOpen(false)}
            >
              Close
            </Button>
            {sending && <CircularProgress />}
            <Button
              variant="contained"
              color="primary"
              disabled={
                confirmEmail !== formData.contactEmail ||
                confirmName !== formData.contactPerson ||
                confirmPhone !== formData.contactPhone ||
                sending === true
              } // Disable if confirmation fields do not match
              onClick={() => {
                sendEmail()
                clickConfirmOrderHandler()
                setFormData({
                  hospitalName: "",
                  contactPerson: "",
                  address: "",
                  city: "",
                  state: "",
                  zip: "",
                  contactEmail: "",
                  contactPhone: "",
                  itemDescription: "",
                  manufacturer: "",
                  mfrNumber: "",
                  unit: "",
                  quantity: "",
                  price: "",
                  selectedItem: "",
                  isNewHospitalData: false,
                })
              }}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Modal>
      {/* Thank-You Modal */}
      <Modal
        open={openThankYouModal}
        onClose={() => setOpenThankYouModal(false)}
      >
        <Box sx={orderModalWrapperStyles}>
          {emailError ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <ReportIcon color="error" fontSize="large" />
              <Typography color="error" fontWeight="bold" textAlign="center">
                THERE'S BEEN AN ERROR
              </Typography>
              <Typography textAlign="center">
                Unable to send the confirmation email. Please try again later.
              </Typography>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ReportIcon color="error" fontSize="small" />
                <Typography textAlign="center">
                  A confirmation email has been sent!
                </Typography>
                <ReportIcon color="error" fontSize="small" />
              </Box>

              <Typography fontWeight={900} textAlign="center" sx={{ mt: 2 }}>
                Please reply to this email with a PO number to complete your
                order.
              </Typography>
            </Box>
          )}
          <Box sx={confirmModalButtonWrapperStyles}>
            <Link to={"/"}>
              <Button
                variant="contained"
                color="error"
                onClick={() => setOpenThankYouModal(false)}
              >
                Close
              </Button>
            </Link>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default OrderForm
