import React, { useContext, useState } from "react"
import {
  Box,
  Drawer,
  Typography,
  List,
  ListItem,
  ListItemButton,
  IconButton,
  Button,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close" // Import Close icon
import SearchIcon from "@mui/icons-material/Search"
import logo from "../../expressmed1-logo-transparent.png" // Adjust the path if needed
import supplyChain from "../../supply-chain.jpg"
import medicalData from "../../Medical_Supply_List.json" // Import the JSON data
import { Search, SearchIconWrapper, StyledInputBase } from "../SearchComponents"
import hospitalData from "../../All_Hospitals_USA.json" // Import the hospital JSON file
import FormContext from "../../Contexts/form-context"
import { Link } from "react-router-dom"
import {
  browseInventoryWrapperStyles,
  drawerHeaderWrapper,
  drawerImageInnerWrapper,
  drawerImageOuterWrapper,
  drawerImageStyles,
  drawerSearchTitleStyles,
  searchBarStyles,
  searchInputBaseStyles,
} from "./MenuDrawer.styles"
import OrderingModal from "../OrderingModal/OrderingModal"

const MenuDrawer = (props) => {
  const [inputValue, setInputValue] = useState("")
  const [suggestions, setSuggestions] = useState([])
  const [selectedItem, setSelectedItem] = useState(null)
  const [hospitalName, setHospitalName] = useState("")
  const [hospitalSuggestions, setHospitalSuggestions] = useState([])
  const [quantity, setQuantity] = useState(null)
  const formCtx = useContext(FormContext)

  // Flatten medicalData to get all items in a single array with relevant fields
  const allItems = Object.values(medicalData).flatMap((category) =>
    category.map((item) => ({
      mfr_number: item.mfr_number || "",
      description: item.description || "",
      manufacturer: item.manufacturer || "",
      unit: item.unit || "",
      price: item.price || "",
    }))
  )

  // Handle input change for /MFR/Description search
  const handleInputChange = (e) => {
    const value = e.target.value.toLowerCase()
    setInputValue(value)

    if (value.length > 0) {
      // Filter items based on input value across multiple fields
      const matchingItems = allItems
        .filter(
          (item) =>
            (item.mfr_number &&
              String(item.mfr_number).toLowerCase().includes(value)) ||
            (item.description && item.description.toLowerCase().includes(value))
        )
        .slice(0, 5) // Limit to top 5 suggestions

      setSuggestions(matchingItems)
    } else {
      setSuggestions([])
    }
  }

  // Handle clicking a suggestion to open the modal
  const handleSuggestionClick = (item) => {
    setInputValue("")
    setSelectedItem(item) // Set the selected item for the modal
    setSuggestions([]) // Clear suggestions after selection
  }

  // Handle modal close
  const handleClose = () => {
    setHospitalName("")
    setQuantity(null)
    setSelectedItem(null) // Clear the selected item to close the modal
    props.closeMenu()
  }

  // Handle hospital input change for typeahead
  const handleHospitalNameChange = (e) => {
    const value = e.target.value
    setHospitalName(value)

    // Filter hospitalData for matching names
    const matchingHospitals = hospitalData
      .filter((hospital) =>
        hospital.hospital_name.toLowerCase().includes(value.toLowerCase())
      )
      .slice(0, 5) // Limit to top 5 suggestions

    setHospitalSuggestions(matchingHospitals)
  }

  // Clear suggestions on blur
  const handleInputBlur = () => {
    setTimeout(() => {
      setSuggestions([])
      setHospitalSuggestions([])
    }, 100)
  }

  // Select a suggestion
  const handleHospitalSuggestionClick = (hospitalName) => {
    setHospitalName(hospitalName)
    setHospitalSuggestions([]) // Clear suggestions after selection
  }

  return (
    <>
      <Drawer anchor="left" open={props.isDrawerOpen} onClose={props.closeMenu}>
        <Box
          role="presentation"
          data-test-id={"drawer"}
          sx={{ maxWidth: "100vw" }}
        >
          {/* Drawer Header */}
          <Box sx={drawerHeaderWrapper}>
            <IconButton onClick={props.closeMenu}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={drawerImageOuterWrapper}>
            <Box sx={drawerImageInnerWrapper}>
              <img src={logo} alt="Logo" style={drawerImageStyles} />
            </Box>
          </Box>
          <Typography variant="h6" sx={drawerSearchTitleStyles}>
            Filter Inventory
          </Typography>

          {/* Search Bar */}
          <Search sx={searchBarStyles}>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search MFR #, or Description"
              inputProps={{ "aria-label": "search" }}
              value={inputValue}
              onChange={handleInputChange}
              sx={searchInputBaseStyles}
            />
          </Search>

          {/* Suggestions List */}
          {suggestions.length > 0 && (
            <List sx={{ bgcolor: "background.paper" }}>
              {suggestions.map((item, index) => (
                <ListItem key={index} disablePadding>
                  <ListItemButton onClick={() => handleSuggestionClick(item)}>
                    <Typography variant="body2">
                      {item.mfr_number} - {item.description}
                    </Typography>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          )}
          <Box sx={browseInventoryWrapperStyles}>
            <Link to={"/inventory"}>
              <Button onClick={props.closeMenu} variant="contained">
                Browse Inventory
              </Button>
            </Link>
          </Box>
          <img src={supplyChain} alt="supplyChain" style={drawerImageStyles} />
        </Box>
      </Drawer>
      {/* Modal */}
      <OrderingModal
        selectedItem={selectedItem}
        handleClose={handleClose}
        setQuantity={setQuantity}
        quantity={quantity}
        hospitalName={hospitalName}
        handleHospitalNameChange={handleHospitalNameChange}
        handleInputBlur={handleInputBlur}
        suggestions={hospitalSuggestions}
        handleSuggestionClick={handleHospitalSuggestionClick}
        formCtx={formCtx}
      />
    </>
  )
}

export default MenuDrawer
