export const cardModalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "80vw", sm: 400 },
  bgcolor: "background.paper",
  p: 4,
  borderRadius: 1,
  overflow: "visible", // Allow content to overflow
}

export const modalFormStyles = {
  marginY: "1rem",
  display: "flex",
  flexDirection: "column",
  gap: 2,
}

export const suggestionListStyles = {
  position: "absolute",
  top: 120,
  width: "90%",
  bgcolor: "background.paper",
  zIndex: 1,
  border: "1px solid black",
}

export const modalCardActionsStyles = { justifyContent: "space-between", mt: 2 }
