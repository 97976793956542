import { Box, Typography, useMediaQuery, useTheme } from "@mui/material"
import React, { useState, useEffect } from "react"
import heroImage from "../../medEquipHero2.jpg"
import heroImage2 from "../../em1HERO1.jpg"

import {
  h1Styles,
  h3Styles,
  heroInnerWrapperStyles,
  heroWrapperStyles,
  heroImageStyles,
} from "./Hero.styles"

const Hero = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const [currentHeroImage, setCurrentHeroImage] = useState(heroImage)
  const [isFading, setIsFading] = useState(false)

  useEffect(() => {
    const imageSwitcher = setInterval(() => {
      setIsFading(true) // Trigger the fade-out effect
      setTimeout(() => {
        setCurrentHeroImage((prevImage) =>
          prevImage === heroImage ? heroImage2 : heroImage
        )
        setIsFading(false) // Reset fade state after transition
      }, 500) // Match the CSS transition duration
    }, 4000)

    // Cleanup interval on component unmount
    return () => clearInterval(imageSwitcher)
  }, [])

  return (
    <Box data-test-id="hero" sx={heroWrapperStyles(isMobile)}>
      <Box
        sx={{
          ...heroImageStyles(currentHeroImage, isFading),
        }}
      />
      <Box sx={heroInnerWrapperStyles}>
        <Typography variant="h1" sx={h1Styles}>
          Express-Med 1
        </Typography>
        <Typography variant="h3" sx={h3Styles}>
          Delivering Excellence in Healthcare Solutions and Supply Chain
          Management
        </Typography>
      </Box>
    </Box>
  )
}

export default Hero
