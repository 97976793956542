import {
  Button,
  Card,
  CardActions,
  FormControl,
  List,
  ListItem,
  ListItemButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material"
import React from "react"
import { Link } from "react-router-dom"
import {
  cardModalStyles,
  modalCardActionsStyles,
  modalFormStyles,
  suggestionListStyles,
} from "./OrderingModal.styles"
import ReactGa from "react-ga"

const OrderingModal = (props) => {
  const {
    selectedItem,
    handleClose,
    setQuantity,
    quantity,
    hospitalName,
    handleHospitalNameChange,
    handleInputBlur,
    suggestions,
    handleSuggestionClick,
    formCtx,
  } = props
  const clickCreateAnOrderFromModalHandler = () => {
    ReactGa.event({
      category: "Button",
      action: "Create An Order from Modal",
    })
  }
  return (
    <Modal open={!!selectedItem} onClose={handleClose}>
      <Card sx={cardModalStyles}>
        {selectedItem && (
          <>
            <Typography variant="h6">{selectedItem.sku}</Typography>
            <Typography variant="body1">{selectedItem.description}</Typography>
            <Typography variant="body2" color="textSecondary">
              Manufacturer: {selectedItem.manufacturer}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              MFR #: {selectedItem.mfr_number}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Unit: {selectedItem.unit}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Price: ${selectedItem.price}
            </Typography>
            <FormControl sx={modalFormStyles}>
              <TextField
                id="order_amounts"
                type="number"
                label="Amount of Orders"
                onChange={(e) => setQuantity(e.target.value)}
                value={quantity}
                placeholder="0"
              />
              <TextField
                id="hospital_name"
                label="Hospital Name"
                value={hospitalName}
                onChange={handleHospitalNameChange}
                onBlur={handleInputBlur} // Clear suggestions on blur
                autoComplete="off"
              />
              {/* Suggestions List */}
              {suggestions.length > 0 && (
                <List sx={suggestionListStyles}>
                  {suggestions.map((hospital, index) => (
                    <ListItem key={index} disablePadding>
                      <ListItemButton
                        onClick={() =>
                          handleSuggestionClick(hospital.hospital_name)
                        }
                      >
                        <Typography variant="body2">
                          {hospital.hospital_name}
                        </Typography>
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              )}
            </FormControl>
          </>
        )}
        <CardActions sx={modalCardActionsStyles}>
          <Button onClick={handleClose}>Close</Button>
          <Link to={"/order"}>
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                clickCreateAnOrderFromModalHandler()
                formCtx.addOrderFormData({
                  hospitalName: hospitalName,
                  quantity: quantity,
                  selectedItem: selectedItem.description,
                })
                handleClose()
              }}
            >
              Create An Order
            </Button>
          </Link>
        </CardActions>
      </Card>
    </Modal>
  )
}

export default OrderingModal
