import React, { useState } from "react"
import {
  Box,
  Grid2,
  TextField,
  Button,
  Typography,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material"
import PhoneIcon from "@mui/icons-material/Phone"
import EmailIcon from "@mui/icons-material/Email"
import LocationOnIcon from "@mui/icons-material/LocationOn"
import {
  h1Styles,
  h3Styles,
  otherHeroInnerWrapperStyles,
  otherHeroWrapperStyles,
} from "../Components/Hero/Hero.styles"
import contactHero from "../contactHero.webp"
import axios from "axios"
import { Helmet } from "react-helmet"
import GoogleMap from "../Components/GoogleMap"

const ContactPage = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  })

  const [formStatus, setFormStatus] = useState("")

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setFormStatus("Submitting...")

    try {
      const response = await axios.post(
        "https://express-med1.onrender.com/contact",
        formData
      )
      if (response.status === 200) {
        setFormStatus("Message sent successfully!")
        setFormData({ name: "", email: "", message: "" })
      } else {
        setFormStatus("Something went wrong. Please try again.")
      }
    } catch (error) {
      console.error("Error submitting form:", error)
      setFormStatus("An error occurred. Please try again.")
    }
  }
  return (
    <Box>
      <Helmet>
        <title>Express-Med1 | Contact</title>
      </Helmet>
      <Box
        data-test-id="inventory_hero"
        sx={otherHeroWrapperStyles(isMobile, contactHero)}
      >
        <Box sx={otherHeroInnerWrapperStyles}>
          <Typography variant="h1" sx={h1Styles}>
            Contact Us
          </Typography>
          <Typography variant="h3" sx={h3Styles}>
            We'd love to hear from you!
          </Typography>
        </Box>
      </Box>
      <Box sx={{ padding: "2rem" }}>
        {/* Header */}
        <Box sx={{ textAlign: "center", mb: 4 }}>
          <Typography variant="h4" gutterBottom>
            Get in Touch
          </Typography>
          <Typography variant="body1">
            We'd love to hear from you! Contact us for any questions or
            assistance.
          </Typography>
        </Box>

        {/* Contact Section */}
        <Grid2 container spacing={4}>
          {/* Contact Form */}
          <Grid2 item xs={12} md={6}>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <Typography variant="h6" gutterBottom>
                Send Us a Message
              </Typography>
              <TextField
                label="Your Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                fullWidth
                required
                margin="normal"
              />
              <TextField
                label="Your Email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                fullWidth
                required
                margin="normal"
              />
              <TextField
                label="Your Message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                multiline
                rows={4}
                fullWidth
                required
                margin="normal"
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                sx={{ mt: 2 }}
                disabled={formStatus === "Submitting..."}
              >
                Submit
              </Button>
              {formStatus && (
                <Typography variant="body2" sx={{ mt: 2, color: "gray" }}>
                  {formStatus}
                </Typography>
              )}
            </Box>
          </Grid2>

          {/* Contact Details */}
          <Grid2 item xs={12} md={6}>
            <Box>
              <Typography variant="h6" gutterBottom>
                Contact Information
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <IconButton color="primary" href="tel:+12242778748">
                  <PhoneIcon />
                </IconButton>
                <Typography variant="body1">224-213-4339</Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <IconButton
                  color="primary"
                  href="mailto:orders@expressmed1.org"
                >
                  <EmailIcon />
                </IconButton>
                <Typography variant="body1">orders@expressmed1.org</Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  color="primary"
                  href="https://www.google.com/maps?q=1+Overlook+Point,+Ste+110,+Lincolnshire,+IL+60069"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LocationOnIcon />
                </IconButton>
                <Typography variant="body1">
                  1 Overlook Point, Ste 110 | Lincolnshire, IL 60069
                </Typography>
              </Box>
            </Box>
          </Grid2>
          <GoogleMap />
        </Grid2>

        {/* Call to Action */}
        <Box
          sx={{
            textAlign: "center",
            mt: 6,
            py: 4,
            backgroundColor: "grey.100",
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Need Immediate Assistance?
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Call our support line for quick help.
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            href="tel:+12242778748"
          >
            Call Us Now
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default ContactPage
